// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

body {
	font-family: 'din-2014', arial, sans-serif;
}


h3 {
	font-size: 1.3rem !important;

	@media(min-width: map-get( $grid-breakpoints, 'md' )){

		font-size: 1.75rem !important;
	}
}

table h3 {
	margin-top: 0.5em;
}

.container {
	min-width: 100%;

	@media(min-width: map-get( $grid-breakpoints, 'lg' )){
		min-width: auto;
	}
}

.block {

	.row:first-child {
		padding-top: 4em;
	}

	.row:last-child {
		padding-bottom: 4em;
	}

	.row+.row {
		padding-top: 2em;
	}

	&-title {
		text-align: center;
	}
}

.hero-block {
	position: relative;
	padding: 6em 0 3em 0;
	@media(min-width: map-get( $grid-breakpoints, 'md' )){
		padding: 9em 0 6em 0;
	}
	@media(min-width: map-get( $grid-breakpoints, 'xl' )){
		padding: 10em 0 7em 0;
	}
	background: url('/dist/images/pitch.jpg') 50% 50% no-repeat;
	background-size: cover;
	color: white;
	text-align: center;
	overflow: hidden;

	&:after {
		display: block;
		content: '';
		background: rgba(0,0,0,0.3);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	* {
		position: relative;
		z-index: 1;
	}

	&-title {
		font-weight: 300;
		font-size: 2rem;
		@media(min-width: map-get( $grid-breakpoints, 'md' )){
			font-size: 3.5rem;
		}
	}

	&-subtitle { 
		font-weight: 300;
		font-size: 1.5rem;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	&-desc { 
		font-weight: 300;
		font-size: 1.25rem;
		font-weight: 500;
	}

	&-input.form-control {
		padding: 0.75em;
		width: 90%;
		@media(min-width: map-get( $grid-breakpoints, 'md' )){
			width: 60%;
		}
		margin: 4px 0.5em;
		max-width: 400px;
		display: inline-block;
		border-color: white;
		border-width: 2px;
	}

	button.btn-outline-primary {
		margin: 4px 0.5em;
		color: white;
		font-weight: 300;
		border-color: white;
		border-width: 2px;
		padding: 0.75em;
		font-size: 1rem;
		background: rgba(0,0,0,0.4);
	}

	a {
		color: white !important;
		text-decoration: underline;
	}
}

fieldset {
	background: #E9EAE8;
	border-radius: 10px;
	padding: 1em;
	margin: 0 -1em 2em;

	@media(min-width: map-get( $grid-breakpoints, 'md' )){
		margin: -1em 0 0;
	}
}

.search-block, .block-grey {
	background: #E9EAE8;
}

.btn {
	font-weight: 300;
	white-space: normal !important;

	&.btn-sm {
		text-transform: uppercase;
		font-size: 14px;
		padding: .1rem .5rem;
		margin-bottom: 0.25rem;
	}
}

.faq {
	list-style: none;
	padding: 0;

	&-link {
		font-size: 1.4em;
		display: block;
		margin: 0.75em 0 0.5em;
	}

	li:first-child &-link {
		margin-top: 0;
	}
}

.tabledata {

	border: none;
	padding: none;
	margin-bottom: 2rem;
	width: 100%;

	th, td {
		padding: 0.25rem;
	}

	.row1 {
		background: #EEF3EF;
	}

	.hover {
		background: #D9E4DC
	}
}

.loading-page {
	height: 100%;

	div {
		height: 100%;
		padding: 0;
		margin: 0 auto;
		text-align: center;
	}

	.hero-block {
		height:100vh;
		padding-top: 100px;

		img {
			height: auto;
			max-width: 180px;
		}
	}
}

#errorbox {
	color: $brand-red;
	border: 1px solid $brand-red;
	padding: 1em;
	margin: 0.5em 0 1em;

	* {
		color: $brand-red;
	}
}

img {
	height: auto;
	max-width: 100%;
}

table img {
	max-width: none;
}

.print-icon {
	float: right;
	display: none;

	@media(min-width: map-get( $grid-breakpoints, 'sm' )){
		display: block;
	}

}

.team-logo {
	float: right;
	margin: 1.5em 0 1.5em 1em;
	max-width: 33.3%;
}

.modal-sm {
    max-width: 306px;
}

.condition-icon:hover {
	cursor: pointer; 
}

.search-form-count {
    font-size: 3em;
    color: #3B731E;
    float: left;
    margin-right: 1rem;
}



    
.blog_thumbnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px;  /* Rounded border */
  padding: 5px; /* Some padding */
  width: 600px; /* Set a small width */
}

input[name='url']:read-only {
  background:  white;
  border:  none;
  color:  grey;
}


.blog_article {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.blog_article-image {
  flex:  1;
}

.blog_article-content {
  flex: 1;

  h3 {
  	margin-bottom: 0;
  }
}

.article-posted {
	color: #999;
	margin: 0 0 0.25rem 0;
}

.articles-list {

	margin: 0 -24px;

	.blog_article {
		flex-direction: column;

		h3 {
			font-size: 24px !important;
			margin-bottom: 4px;
		}

		.blog_article-image {
			margin: 1rem 0 1rem 0;
			border: 1px solid #ddd;
    	border-radius: 4px;
			width: 100%;
			padding: 5px;
		}

		.blog_article-image--inner {
			display: block;
			width: 100%;
			padding-top: 50%;
			position:relative;
			overflow: hidden;

			.blog_thumbnail {
				border: none;
				padding: 0;
				border-radius: 0;
			}

			img {
			  position: absolute;
			  left: 50%;
			  top: 50%;
			  height: auto;
			  width: 100%;
			  -webkit-transform: translate(-50%,-50%);
			      -ms-transform: translate(-50%,-50%);
			          transform: translate(-50%,-50%);
			}
		}
	}
}
