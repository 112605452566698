.search-form {


	&-group--competition, &-group--matchtype {
		position: relative;
		padding-left: 2.25em;
		min-height: 4em;

		&:last-child {
			min-height: 3em;
		}

		&:before {
			content: '1';
			display: inline;
			position: absolute;
			left: 0;
			top: -6px;
			font-size: 3em;
			line-height: 1em;
			color: #ccc;
		}

		select {
			margin-top: 0.2em;
		}

		label {
			display: none;
		}
	}

	&-group--matchtype {
		&:before {
			content: '2';
		}
	}

	&-actions {
		white-space: nowrap;
	}

	&-count {
		font-size: 3em;
		color: $brand-green;
		float: left;
		margin-right: 1rem;
	}

	button {
		margin-top: 0.5em;
		float: left;
	}
}

.search-frame {
	margin-bottom: -60px;
}

.search-key {
	width: 100%;
	margin-bottom: 2em;

	@include clearfix;

	@media(min-width: map-get( $grid-breakpoints, 'sm' )){
		width: 33.3%;
		min-width: 200px;
		float: right;
		margin-left: 2rem; 
	}
	background: $brand-green-light;
	border-radius: 10px;
	padding: 1rem;

	table {
		width: auto;
		float: left;
		margin-right: 1em;

		@media(min-width: map-get( $grid-breakpoints, 'sm' )){
			width: 100%;
			float: none;
			margin-right: 0;
		}

		background: #fff;
		margin-bottom: 0.5rem;

		td {
			padding: 3px;
		}
	}
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; font-size: 1rem; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3B731E; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }