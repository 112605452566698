$brand-green: 			#3B731E;
$brand-green-light: 	#EEF3EF;
$brand-red:				#d9534f;
$link-color: 			$brand-green;

$btn-primary-color: 	#fff;
$btn-primary-bg: 		$brand-green;
$btn-primary-border: 	$brand-green;

$btn-secondary-color:   $brand-green;
$btn-secondary-border:  $brand-green;


 