.navbar {
	z-index: 9;
	background: rgba(0,0,0,0.5);
	position: absolute !important;
	width: 100%;
	padding: .5rem 1.5rem;

	@media(max-width: map-get( $grid-breakpoints, 'lg' )){
		.container {
			padding: 0;
		}
	}

	@media(min-width: map-get( $grid-breakpoints, 'md' )){
		background: none;
	}

	@media(min-width: map-get( $grid-breakpoints, 'lg' )){
		font-size: 1.125rem; //18px
	}

	&.navbar-inverse {

		.navbar-toggler {
			margin-top: 2px;
			padding: 0;
			border-color: transparent;
			cursor: pointer;
			margin-right: -10px;

			@media(min-width: map-get( $grid-breakpoints, 'sm' )){
				margin-right: 0;
				margin-top: 6px;
			}

			&.navbar-toggler-right {
				right: 0;
			}

			&:focus, &:active, &:hover {
				outline: none;
				opacity: 1;
			}

			span {
				opacity: 0.75;
				background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/></svg>");
			}
		}

		.navbar-nav .nav-link {
			color: rgba(255,255,255,.9);
			font-weight: 300;
			text-align: center;
			margin-bottom: 1px;
			background: rgba(0,0,0,0.75);
			border-radius: 5px;

			@media(min-width: map-get( $grid-breakpoints, 'md' )){
				border: none;
				background: none;
			}

			&:hover {
				color: rgba(255,255,255,1);
			}
		}
		.navbar-nav li:last-child .nav-link {
			border-bottom: none;
		}
	}

	.navbar-nav {
		@media(min-width: map-get( $grid-breakpoints, 'md' )){
			margin-top: 1.4em;
		}
		@media(min-width: map-get( $grid-breakpoints, 'lg' )){
			margin-top: 1.15em;
		}
		margin-bottom: 0.5em;
	}

	.dropdown-item.active, .dropdown-item:active {
		background-color: #f7f7f9 !important;
	}

	.navbar-brand {
		font-family: 'din-2014', sans-serif;
		text-transform: uppercase;
		font-weight: 200;
		font-size: 1.1rem;
		margin-left: -5px;


		@media(min-width: map-get( $grid-breakpoints, 'sm' )){
			font-size: 1.5rem;
			margin-left: 0;
		}

		@media(min-width: map-get( $grid-breakpoints, 'md' )){
			font-size: 2rem;
		}

		span {
			font-size: 1rem;
		}
	}

	.navbar-basket {
		position: absolute;
		right: 1.5rem;
		top: 0.3rem;

		@media(min-width: map-get( $grid-breakpoints, 'sm' )){
			right: 2.5rem;
			top: 0.6rem;
		}

		strong {
			position: absolute;
			display: block;
			width: 20px;
			height: 20px;
			background: darken($brand-green, 5);
			border-radius: 100%;
			line-height: 20px;
			text-align: center;
			color: white;
			top: 0;
			right: -2px;
			z-index: 1;
			font-weight: normal;
			font-size: 14px;
		}

		span {
			margin-left: 0.5rem;
			display: inline-block;
			float: right;
			width: 2rem;
			height: 1.5rem;
			vertical-align: middle;
			background: url(../images/shopping-cart.svg) 50% 50% no-repeat;
			background-size: 1.4rem;
			padding-right: 1rem;
			opacity: .75;
		}

		@media(min-width: 460px){
			display: block;
			
		}

		@media(min-width: map-get( $grid-breakpoints, 'md' )){
			position: relative;
			top: auto;
			right: auto;
			margin-top: 0.35rem;

			span {
				overflow: hidden;
				text-indent: -9999px;
				opacity: 1;
				background-size: 1.8rem;
			}
		}
	}
}